/*global $:false*/
/*global escape: true */
/*jslint browser: true*/
function windowSize() {
	'use strict';
    var w = Math.max($(window).width(), window.innerWidth),
        type = 'Default';
    
    if (w > 1441) {
        type = 'XL';
    } else if (w <= 1440 && w > 1280) {
        type = 'Large';
    } else if (w <= 1280 && w > 1024) {
        type = 'Medium';
    } else if (w <= 1024 && w > 440) {
        type = 'Small';
    } else {
        type = 'Default';
    }
  
    // change the css file of the tag with id="stl" and rel="stylesheet"
	$('body').removeClass('XL Large Medium Small Default').addClass(type);
}

function moveElements() {
    'use strict';
    // Move Secondary Navigation
    $('.Default .Search #SecondaryNavWrap, .Small .Search #SecondaryNavWrap').appendTo('.Menu .NavContent');
    $('.Medium .Menu #SecondaryNavWrap, .Large .Menu #SecondaryNavWrap, .XL .Menu #SecondaryNavWrap').insertBefore('#Search');
      
    // MainNav - remove inline display none from toggle
    $('.Medium .Menu, .Large .Menu, .XL .Menu').css('display', '');
    
    // Move Left Column Panels
    $('.Interior.Default #ColumnOne #LeftColumnPanels').insertAfter('#ColumnThree #CallToAction');
    $('.Interior.Medium #ColumnThree').appendTo('#ColumnOne');
    $('.Interior.Small #ColumnOne #ColumnThree, .Interior.Large #ColumnOne #ColumnThree, .Interior.XL #ColumnOne #ColumnThree').insertAfter('#ColumnTwo');
    $('.Interior.Small #ColumnOne #LeftColumnPanels').prependTo('#ColumnThree');
    $('.Interior.Large #ColumnThree #LeftColumnPanels, .Interior.XL #ColumnThree #LeftColumnPanels').appendTo('#ColumnOne');

    // Move Columns on LeftCol template
    $('.LeftCol.Default #ColumnOne #LeftColumnPanels, .LeftCol.Small #ColumnOne #LeftColumnPanels').insertAfter('#ColumnTwo');
    $('.LeftCol.Medium #LeftColumnPanels, .LeftCol.Large #LeftColumnPanels, .LeftCol.XL #LeftColumnPanels').appendTo('#ColumnOne');
        
    //Reorder the markup on doctor details pages 
    $('.Default #DrDetail a.HasVideo, .Small #DrDetail a.HasVideo').insertAfter('.Designations');
    $('.Medium #DrDetail a.HasVideo, .Large #DrDetail a.HasVideo, .XL #DrDetail a.HasVideo').prependTo('.QuickInfo');

}

function equalHeight(group) { //make columns equal heights
	'use strict';
    var tallest = 0;
	group.each(function () {
		var thisHeight = $(this).height();
		if (thisHeight > tallest) {
			tallest = thisHeight;
		}
	});
	group.css('min-height', tallest);
}

// Change Tabs into Accordions
function tabsToAccordions() {
    'use strict';
	// do this for each tab container
	$('.TabContainer').each(function (k, TabContainer) {

		// new accordion container (jquery object)
		var newAccordion = $('<div class="ResponsiveAccordion Accordion ' + $(this).data('accordionClasses') + '"></div>');

		// loop through all tabs in the current tab container
		$(this).find('.Tab').each(function (k, tab) {

			// create objects for the new trigger and payload
			var accordionTrigger = $('<h3>' + $(tab).find(':header:first').text() + '</h3>'),
                accordionPayload = $('<div class="TheTabContent"></div>');

			// add trigger and empty payload to the accordion container
			accordionTrigger.appendTo(newAccordion);
			accordionPayload.appendTo(newAccordion);

			// when clicking on an accordion trigger, get the contents from the tab and put it in the payload... only if it's empty
			accordionTrigger.mousedown(function (e) {
				if (accordionPayload.is(':empty')) {
					var newTabContents = $(tab).clone();

					// if there's a google map in the tab, then do some funky stuff
					if (newTabContents.find('.MapContainer').length() > 0) {
						$(tab).find('#MapId').attr('id', 'MapIdOriginal');
						$(tab).find('.MapContainer').attr('class', 'MapContainerOriginal');
						newTabContents.find('#MapId').remove();
						newTabContents.find('.GoogleLocateOnMapLink').remove();
						setTimeout(function () { Geo_InitMap(); }, 1000);
					}

					// put tab contents in the payload
					newTabContents.appendTo(accordionPayload);

				}
			}); // end click event

		});
		$(TabContainer).after(newAccordion);
	});
    
	doAccordions();
    
    // if you addded a 'StartOn' class to make an accordion payload automatically visible, trigger it so it has content
    $('.ResponsiveAccordion[class*="StartOn"]').each(function () {
        $(this).find('.Trigger').eq(parseInt($(this).attr('class').split('StartOn')[1]) - 1).trigger('mousedown');
    });
}

function addEmailLink() {
	'use strict';
    var clientDomainName = 'Client Domain Name',
		clientSiteName = 'Client Name',
		currentPageUrl = document.location,
		currentPageTitle = $.trim($('title').text()),
		emailSubject = clientDomainName + ': ' + currentPageTitle,
		emailHref = 'mailto:?subject=' + escape(emailSubject) + '&body=I thought you would be interested in a page on the ' + escape(clientSiteName) + ' Web site: ' + escape(currentPageTitle) + ' - ' + escape(currentPageUrl);
	$('#PageTools').append('<div class="EmailLink"><a href="' + emailHref + '"><div class="fa fa-envelope-o"><span>Email this page</span></div></a></div><div class="PrintLink"><a href="javascript:;" onclick="print();"><div class="fa fa-print"><span>Print this page</span></div></a></div>');
}

function addMaskedEntries() {
	'use strict';
    $(".PhoneMasked input, input.PhoneMasked").mask("999-999-9999", { placeholder: " " });
	$(".PhoneMaskedExt input, input.PhoneMaskedExt").mask("999-999-9999? x999", { placeholder: " " });
	$(".DateMasked input, input.DateMasked").mask("99/99/9999", { placeholder: " " });
	$(".SSNMasked input, input.SSNMasked").mask("999-99-9999", { placeholder: " " });

	$('.PhoneMasked small:contains("Example: 123-123-1234")').hide();
	$('.PhoneMaskedExt small:contains("Example: 123-123-1234")').hide();
	$('.SSNMasked small:contains("Example: 000-00-0000")').hide();
}

function moveCTAPanel() {
	/* Call To Action */
	// If Move-CTA-Here exisits - move the CTA to that spot
	// If Move-CTA-Here does not exists - check to see if there is an h1 imediately followed by an h2 and move it after that h2
	// If no h2 exists imediately after an h1, move CTA after the h1 instead
	// If no Move-CTA-Here, H1 or H2 exists - move CTA to the top of the cmsPageContent

    'use strict';
    var InsertHere;
    if ($('body.LeftCol #CallToAction, body.NoCols #CallToAction').length > 0) {
        if ($('.Move-CTA-Here:first').length > 0) {
            $('#CallToAction').appendTo('.Move-CTA-Here');
        } else if ($('.cmsPageContent h1:first').length > 0) {
            if ($('.cmsPageContent  h1:first').next('h2').length > 0) {
                InsertHere = $('.cmsPageContent h1:first').next('h2');
                $('#CallToAction').insertAfter(InsertHere);
            } else {
                InsertHere = $('.cmsPageContent h1:first');
                $('#CallToAction').insertAfter(InsertHere);
            }
        } else {
            $('#CallToAction').prependTo('.cmsPageContent');
        }
    } else if ($('body.RightCol #CallToAction').length > 0) {
        if ($('.Default .Move-CTA-Here:first, .Small .Move-CTA-Here:first').length > 0) {
            $('.Small #ColumnThree #CallToAction').appendTo('.Small .Move-CTA-Here');
            $('.Default #ColumnThree #CallToAction').appendTo('.Default .Move-CTA-Here');
        } else if ($('.Default .cmsPageContent h1:first, .Small .cmsPageContent h1:first').length > 0) {
            if ($('.Default .cmsPageContent  h1:first, .Small .cmsPageContent  h1:first').next('h2').length > 0) {
                InsertHere = $('.cmsPageContent h1:first').next('h2');
                $('.Default #ColumnThree #CallToAction, .Small #ColumnThree #CallToAction').insertAfter(InsertHere);
            } else {
                InsertHere = $('.cmsPageContent h1:first');
                $('.Default #ColumnThree #CallToAction, .Small #ColumnThree #CallToAction').insertAfter(InsertHere);
            }
        } else {
            $('.Default #ColumnThree #CallToAction, .Small #ColumnThree #CallToAction').prependTo('.cmsPageContent');
        }
        $('.Medium .cmsPageContent #CallToAction,.Large .cmsPageContent #CallToAction, .XL .cmsPageContent #CallToAction').prependTo('#ColumnThree');
    } else {
        if ($('.Default .Move-CTA-Here:first, .Small .Move-CTA-Here:first, .Medium .Move-CTA-Here:first').length > 0) {
            $('.Medium #ColumnThree #CallToAction').appendTo('.Medium .Move-CTA-Here');
            $('.Small #ColumnThree #CallToAction').appendTo('.Small .Move-CTA-Here');
            $('.Default #ColumnThree #CallToAction').appendTo('.Default .Move-CTA-Here');
        } else if ($('.Default .cmsPageContent h1:first, .Small .cmsPageContent h1:first, .Medium .cmsPageContent h1:first').length > 0) {
            if ($('.Default .cmsPageContent  h1:first, .Small .cmsPageContent  h1:first, .Medium .cmsPageContent h1:first').next('h2').length > 0) {
                InsertHere = $('.cmsPageContent h1:first').next('h2');
                $('.Default #ColumnThree #CallToAction, .Small #ColumnThree #CallToAction, .Medium #ColumnThree #CallToAction').insertAfter(InsertHere);
            } else {
                InsertHere = $('.cmsPageContent h1:first');
                $('.Default #ColumnThree #CallToAction, .Small #ColumnThree #CallToAction, .Medium #ColumnThree #CallToAction').insertAfter(InsertHere);
            }
        } else {
            $('.Default #ColumnThree #CallToAction, .Small #ColumnThree #CallToAction, .Medium #ColumnThree #CallToAction').prependTo('.cmsPageContent');
        }
        $('.Large .cmsPageContent #CallToAction, .XL .cmsPageContent #CallToAction').prependTo('#ColumnThree');
    }
    // if call to action is empty or contains just spaces - remove it
    $('#CallToAction').filter(function () {
        return $.trim($(this).html()) === '';
    }).remove();
}

function breadArrow() {
	'use strict';
    // replace greater than sign with image on large devices
	if ($('#Breadcrumbs').length) {
        $('#Breadcrumbs p').html(
            $('#Breadcrumbs p').html().replace(/&gt;/g, '&#47;')
        );
    }
}

function truncateBoxDay() {
	'use strict';
    $('.BoxDay span:contains("Sunday")').text("Sun");
	$('.BoxDay span:contains("Monday")').text("Mon");
	$('.BoxDay span:contains("Tuesday")').text("Tue");
	$('.BoxDay span:contains("Wednesday")').text("Wed");
	$('.BoxDay span:contains("Thursday")').text("Thur");
	$('.BoxDay span:contains("Friday")').text("Fri");
	$('.BoxDay span:contains("Saturday")').text("Sat");
}

//check for the orientation event and bind accordingly
if (window.DeviceOrientationEvent) {
	window.addEventListener('orientationchange', windowSize, moveElements, false);
}

function addPageTools() {
    'use strict';
    if ($(window).width() > 1024) {
        if (!$('#PageTools').length) {
            $('#Breadcrumbs').after('<div id="PageTools"></div>');
            addEmailLink();
        }
    } else {
        $('#PageTools').remove();
    }
}

function flexSlider() {
    'use strict';
    if ($('.flexslider').length > 0) {
        $('.flexslider').flexslider({
            animation: "slide",
            slideshowSpeed: 7000,
            animationLoop: true,
            pauseOnHover: true
        });
    }
}

function convertTable() {
    'use strict';
    // Convert table to divs with classes
    $('.CalendarDetailContent table.CalendarEventDetailHeader').replaceWith(
        $('.CalendarDetailContent table.CalendarEventDetailHeader').html()
            .replace(/<tbody/gi, "<div class='Table'")
            .replace(/<tr/gi, "<div")
            .replace(/<\/tr>/gi, "</div>")
            .replace(/<td/gi, "<div")
            .replace(/<\/td>/gi, "</div>")
            .replace(/<\/tbody/gi, "<\/div")
    );
    
    // Convert table to divs with classes
    $('.AccordionContentInside table.CalendarEventDetailHeader').replaceWith(
        $('.AccordionContentInside table.CalendarEventDetailHeader').html()
            .replace(/<tbody/gi, "<div class='Table'")
            .replace(/<tr/gi, "<div")
            .replace(/<\/tr>/gi, "</div>")
            .replace(/<td/gi, "<div")
            .replace(/<\/td>/gi, "</div>")
            .replace(/<\/tbody/gi, "<\/div")
    );
}

function clickToCall() {
    /* 
    This is a global click to call function. This will match phone numbers in these formats and convert them to anchors with 'tel' attributes:

    123 456 7890
    (123)456 7890
    (123) 456 7890
    (123)456-7890
    (123) 456-7890
    123.456.7890
    123-456-7890

    Example: <a href="tel:1234567890">(123) 456-7890</a>
    */
    'use strict';
    var phoneRegEx = new RegExp(/([(]?\d{3}[)]?[(\s)?.-]\d{3}[\s.-]\d{4})(?![^<]*>|[^<>]*<\/)/g);

    /*
    RegEx Explained

    /           Start the expression
    [(]?        Optional left parenthesis
    \d{3}\      Look for 3 digits (area code)
    [)]?        Optional right parenthsis
    [(\s)?.-]   Optional space (after the area code) or a dot or dash
    \d{3}       Look for 3 digits (the first part of the phone num)
    [\s.-]      Either a space, dot, or dash to separate the first 3 numbers of the phone number
    \d{4}       The last 4 digits of the phone num
    (?![^<]*>|[^<>]*<\/)  This is a negative lookup. Make sure the number is not directly nested in any tag. Ex: a tag with an ID or class that has digits in this format 
    /g          Global search

    */

    //search through the immediate children of the body and construct a new jQuery object from the matching elements.
    $("body *").contents().each(function() {
        //return if nodeType isn't a text node (Text Node: Represents textual content in an element or attribute. No children.)
        if (this.nodeType !== 3) {
            return;
        }
        //we don't want to change phone numbers that are already in a link
        if($(this).closest('a').length) {
            return;
        }
        //test against the regEx
        var match = $(this).text().match(phoneRegEx);
        if (match === null || match.length === 0) {
            return;
        }
        var newhtml = $(this).text().replace(phoneRegEx, function(match){
            //remove spaces, dashes, dots and parenthesis for the href
            var phonenumber = match.replace(/ /g, "").replace(/-/g, "").replace(/\./g, "").replace(/\(/g, "").replace(/\)/g, "");
            var link = '<a href="tel:' + phonenumber + '">' + match + '</a>';
            return link;
        });
        $(this).replaceWith(newhtml);
    });
}

function isAppleDevice() { 
	'use strict';
	if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
	    return true;
	} else {
	    return false;
	}
}

function isAndroidDevice() { 
	'use strict';
	if( /Android/i.test(navigator.userAgent)) {
		return true;
	} else {
	    return false;
	}
}


$(function () {
    'use strict';
    // Forces the first "Tab" to be open on mobile sizes when the tabsToAccordions funciton is used
    $('#DrDetail .TabContainer').data('accordionClasses', 'StartOn1');
    $('#ServicesSearch .TabContainer').data('accordionClasses', 'StartOn1');
    $('#DrSearch .TabContainer').data('accordionClasses', 'StartOn1');
    $('#CalendarSearch .TabContainer').data('accordionClasses', 'StartOn1');
});

$(window).ready(function () {
	'use strict';
    tabsToAccordions();
});

//bind to resize
$(window).resize(function () {
	'use strict';
    windowSize();
	moveElements();
    breadArrow();
	moveCTAPanel();
    addPageTools();
});

//check for the orientation event and bind accordingly
$(window).on('orientationchange', function () {
	'use strict';
    windowSize();
	moveElements();
    breadArrow();
	moveCTAPanel();
    addPageTools();
});

$(document).ready(function () {
    'use strict';
    windowSize();
	moveElements();
    moveCTAPanel();
    breadArrow();
    addPageTools();
	truncateBoxDay();
    flexSlider();
    
    // ----------- TEMPLATE EXTRAS ------------------//
	addMaskedEntries(); // Needed for Event & Form inputs that are masked. -dab
    
    /* Click to Call */
    if(isAppleDevice() || isAndroidDevice() == true) {
        clickToCall();
    }
    
    
    /* Mobile Navigation 
    ----------------------------------------------------------------- */
	$('.CustomToggles').each(function () {
		// create variables to use for the triggers and payloads
		var $CT = $(this),
            $triggers = $CT.find('.Triggers>li'),
            $payloads = $CT.find('.Payloads>li');

		// loop thru the triggers - using "i" as the counter, you can associate the tigger with the payload
		$triggers.each(function (i, trigger) {

			// clicking a trigger will slide up all payloads and will only slide down
			// if the associated payload wasn't the one you triggered
			$(trigger).click(function () {
				$triggers.removeClass('Active');

                if ($payloads.eq(i).is(':hidden')) {
					$payloads.slideUp();
					// slide down the associated payload
					// .slideDown(1000) is 1 second
					// .slideDown(1000, function(){ do.something })
					// do.something happens after the slideDown command is finished.
					// zIndexTop is a class to make sure that the one you
					// clicked on is always sliding over the others.
					$payloads.eq(i).addClass('zIndexTop').slideDown(300, function () {
						$(this).removeClass('zIndexTop');
						$triggers.eq(i).addClass('Active');
					});
				} else {
					$payloads.slideUp();
				}
			});
		});
	});
   
    /* Count MainNav items and apply class
    ----------------------------------------------------------------- */
    $('#MainNav h2').insertBefore('#MainNav');
    var MainNavCount = $('#MainNav ul li').length;
	MainNavCount = MainNavCount.toString();
    $('#MainNav ul li').each(function () {
        $(this).addClass('Count' + MainNavCount);
    });
    
    /*  Prox Nav Accordion
    ----------------------------------------------------------------- */
	if ($('body.Default, body.Small, body.Medium').length > 0) {
        $('#ProximityToggle').addClass('Closed');
        $('#ProximityNavWrap1').css('display', 'none');
    }
    $("#ProximityToggle").click(function () {
		$("#ProximityNavWrap1").slideToggle();
		$("#ProximityToggle").toggleClass("Closed");
		return false;
	});
    
    /*  Remove margin-bottom from paragraphs with a button inside of them
        The p tag is automatically wrapped around anchors in TinyMCE 
    ----------------------------------------------------------------- */
    $('.Button').each(function () {
        if ($(this).parent('p')) {
            $(this).parent('p').addClass('NoSpace');
        }
    });
    
    // Keeps accordions from jumping to the top of the page when accordion is really long
    // Reason for this hack: sticky nav was covering up the top of the accordion
    /*----------------------------------------------------------------- */
    $('.Accordion .Trigger').each(function () {
        $(this).mouseup(function () {
            $('html, body').animate({ scrollTop: $(window).scrollTop() });
        });
    });
    $('#DrDetail .Tabnav li').each(function () {
        $(this).mouseup(function () {
            $('html, body').animate({ scrollTop: $(window).scrollTop() });
        });
    });
    
    /* Add VideoContainer around iframed YouTube videos
    ---------------------------------------------------------------- */
	$('iframe[src*="youtube.com"]').each(function () {
		$(this).wrap('<div class="VideoContainer" />');
	});

	/*  FancyBox for Various Scenarios
    ----------------------------------------------------------------- */
	$("a.YouTubeVideo").fancybox({ type: 'iframe' });
    $("a.FancyImage").fancybox();
	$("a.FancyYouTube").fancybox({ type: 'iframe' });
	$("a.Fancyiframe").fancybox({ type: 'iframe' });

	$('<span class="YouTubeVideoButton" />').insertAfter('a.YouTubeVideo img');
    
    
    /*  YouTube Playlist function
    ----------------------------------------------------------------- */
    $('.PlaylistContainer').each(function () {

        $.each($(".PlaylistContainer > #ytvideo"), function (index, value) {
            var num = index + 1;
            $(value).attr("id", "ytvideo" + num);
        });

        $.each($(".PlaylistContainer > ul"), function (index, value) {
            var num = index + 1;
            $(value).attr("class", "ytplaylist" + num);
        });

        // You can have up to 8 YouTube playlists on a page. If you need more you will need to add more lines here.
        $("ul.ytplaylist1").ytplaylist({
            addThumbs: true,
            holderId: 'ytvideo1'
        });
        $("ul.ytplaylist2").ytplaylist({
            addThumbs: true,
            holderId: 'ytvideo2'
        });
        $("ul.ytplaylist3").ytplaylist({
            addThumbs: true,
            holderId: 'ytvideo3'
        });
        $("ul.ytplaylist4").ytplaylist({
            addThumbs: true,
            holderId: 'ytvideo4'
        });
        $("ul.ytplaylist5").ytplaylist({
            addThumbs: true,
            holderId: 'ytvideo5'
        });
        $("ul.ytplaylist6").ytplaylist({
            addThumbs: true,
            holderId: 'ytvideo6'
        });
        $("ul.ytplaylist7").ytplaylist({
            addThumbs: true,
            holderId: 'ytvideo7'
        });
        $("ul.ytplaylist8").ytplaylist({
            addThumbs: true,
            holderId: 'ytvideo8'
        });
    });
    
     /* Smooth scroll back to top of page  
	----------------------------------------------------------------- */
	$('#ToTop').click(function (event) {
        var duration = 500;
	
        event.preventDefault();
		$('html, body').animate({ scrollTop: 0 }, duration);
		return false;
	});
    
    /* Locations Module
    ----------------------------------------------------------------- */
    // Move SearchBox above tabs if tabs exist
    if ($('.LocationsTabs').length) {
        $('#LocationsSearch .SearchBox').insertBefore('.LocationsTabs').addClass('LocationSearchBox');
    }
    if ($('.LocationsList').length > 0) {
		$('.LocationsList > ul > li').each(function () {
			$(this).addClass('ClearFix');
			var Phone = $(this).find('.Phone').html();
			if (Phone !== null) {
				$(this).find('.Headline').after('<div class="MovedPhone">' + Phone + '</div>');
			}
			$(this).find('.Phone').remove();
			$(this).find('.MajorDetails').after($(this).find('.MinorDetails'));
			$(this).find('.Headline,.MovedPhone').wrapAll('<div class="NamePhone" />');
			$(this).find('.MajorDetails,.MinorDetails').wrapAll('<div class="Details" />');
		});
	}

    if ($('#LocationsDetail').length) {
        $('#LocationsDetail .Wrapper, #LocationsDetail .ContactWrapper').wrapAll('<div class="LocationHeader ClearFix" />');
    }
    
    /* Locations SmartPanel
    ----------------------------------------------------------------- */
    if ($('.SmartPanel .LocationList').length) {
        $('.SmartPanel .LocationList dl').each(function () { $(this).find('dt, dd.Phone, dd.Address').wrapAll('<div class="LocationInfo" />'); });
    }
    
    /* Doctors Module
    ----------------------------------------------------------------- */
    // Switches the order of speciality and # of doctors in the DrSearch tab container
    if ($('#DrSearch #ProviderBrowse').length) {
		$('#DrSearch #ProviderBrowse #BrowseTab .Tab dl span').each(function () {
            $(this).next('a').insertBefore(this);
        });
        $('#DrSearch #ProviderBrowse .TheTabContent dl span').each(function () {
            $(this).next('a').insertBefore(this);
        });
    }
    
    // Reorder the markup on the search results page
    if ($('#DrResults .DrList').length) {
		$('#DrResults .DrList > li > dl').each(function () {
			$(this).find('dt:first').before($(this).find('dd.Picture'));
			$(this).find('dt:first').addClass('ResultsDocName');
			$(this).find('.ResultsDocName,.Specialties,.Locations').wrapAll('<div class="DocResultsDetails" />');
			$(this).find('.ExtraInfo').after($(this).find('.DocResultsDetails'));
		});
	}
    
    /* News Module
    ----------------------------------------------------------------- */
    // Add SearchBox div - but only to pages that do NOT have fieldset.BlockLabels
    // fieldset.BlockLabels are used on all VitalSite forms
    if (!$('#PageContent .cmspage.FormContainer .SearchBox').length) {
        if (!$('.FormContainer fieldset.BlockLabels, .CalendarDetailContent fieldset').length) { $('.FormContainer fieldset').wrap('<div id="PageSearchdiv" class="ModuleSearch">').wrap('<div class="SearchBox" />'); }
    }
    
    /* Calendar Details Page
    ----------------------------------------------------------------- */
    if ($('.CalendarDetailContent .EventStatus').length) {
        $('.CalendarDetailContent .EventStatus').insertAfter('.CalendarDetailContent fieldset');
    }
    
    if ($('.cmspage .CalendarDetail').length) { convertTable(); }
    
     // check to see how many inputs are in the searchbox and the apply a class so we can style accordingly
    if ($('#PageContent .cmspage .CalendarList .SearchBox .categoryDropDownDiv').length) {
        $('.CalendarList .SearchBox').addClass('WithDropdown');
    }
    
    // if p.MoreLink is empty - remove it
    $('p.MoreLink').each(function () {
        if ($(this).html().trim().length <= 0) {
            $(this).remove();
        }
    });
    
    /* Clinical Trials
    ----------------------------------------------------------------- */
    // Switches the order of trial name and # of trails in the Clinical Trials tab container
    if ($('#ClinicalTrialsSearch .BrowseTabs').length) {
		// Tabs 
        $('#ClinicalTrialsSearch .BrowseTabs .Tab dt span').each(function () {
            $(this).next('a').insertBefore(this);
        });
        // Tabs to accordions block
        $('#ClinicalTrialsSearch .BrowseTabs .TheTabContent dt span').each(function () {
            $(this).next('a').insertBefore(this);
        });
    }
    
    /* Forms & Documents
    ----------------------------------------------------------------- */
    // Switches the order of Department and # of documents in the Forms & Docs tab container
    if ($('#FormsAndDocuments .BrowseTabs').length) {
		// Tabs 
        $('#FormsAndDocuments .BrowseTabs .Tab dt span').each(function () {
            $(this).next('a').insertBefore(this);
        });
        // Tabs to accordions block
        $('#FormsAndDocuments .BrowseTabs .TheTabContent dt span').each(function () {
            $(this).next('a').insertBefore(this);
        });
    }
    
    // run equalHeights Script on load
	equalHeight($('.CheerCatDetail .CheerCategory')); // cheercards
});

// ----------- AFTER PAGE LOAD ------------------//
$(window).on("load", function () {
    'use strict';
    // If video has YouTube video, wrap with Video Container
    // this is used on doctor profiles with videos
    $('iframe.YouTubeVideoPlayer').wrap('<div class="VideoContainer" />');
    
    // Calendar Module -- if two date boxes appear add class Two to the second one
    $('.CalendarDetailContent .Table div.DateBox:eq(1)').addClass("Two");
});